<template>
    <v-container fluid class="pl-0 pr-0">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="9">
                <v-card outlined>
                    <v-card-title :class="`mt-5 ${getTitleSize()}`">
                        <v-divider class="ma-5" />
                            Gallery
                        <v-divider class="ma-5" />
                    </v-card-title>
                    <zoom-center-transition :duration="500">
                        <v-card-text v-show="filteredImages.length > 0">
                            <v-row justify="center">
                                <v-col cols="11" md="6">
                                    <v-select
                                        outlined
                                        flat
                                        v-model="filter"
                                        :hide-no-data="!search" 
                                        :items="folders"
                                        :search-input.sync="search"
                                        hide-selected
                                        label="Select Filter"
                                        multiple
                                        small-chips
                                        deletable-chips
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                            <zoom-center-transition group :duration="500" tag="div" :class="getBreakpointName() === 'xs' ? 'row' : 'row mx-4'">
                                <v-col v-for="(image, index) in filteredImages" :key="image.id" cols="12" sm="6" lg="4" xl="3">
                                    <v-lazy>
                                        <HoverCard>
                                            <template v-slot:content>
                                                <v-card outlined>
                                                    <v-img @click="openCarousel(index)" :src="image.thumbnails" height="250px">
                                                        <template v-slot:placeholder>
                                                            <v-container class="fill-height">
                                                                <v-row justify="center">
                                                                    <v-col cols="auto">
                                                                        <v-icon size="128px">{{ mdiImageFilterHdr }}</v-icon>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </template>
                                                    </v-img>
                                                </v-card>
                                            </template>
                                        </HoverCard>
                                    </v-lazy>
                                </v-col>
                            </zoom-center-transition>
                        </v-card-text>
                    </zoom-center-transition>
                    <v-card-text v-show="filteredImages.length === 0">
                        <v-row justify="center">
                            <v-col cols="11">
                                <v-card outlined>
                                    <v-card-text class="text-center title">No images found.</v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog :content-class="`slider elevation-0 ${this.getBreakpointName() === 'xs' ? 'mx-0' : 'mx-3'}`" v-model="overlay" :overlay-opacity="opacity">
            <div class="slider">
                <v-btn 
                    fab 
                    outlined 
                    dark 
                    v-show="overlay" 
                    @click="closeCarousel()" 
                    :class="`close float-right ${this.getBreakpointName() === 'xs' ? 'mx-3' : 'mx-0'}`" 
                    :small="getBreakpointName() === 'xs'"
                >
                    <v-icon>{{ mdiCloseOutline }}</v-icon>
                </v-btn>
                <ImageCarousel v-show="overlay" :images="urls" :imageIndex="imageIndex" />
            </div>
        </v-dialog>
    </v-container>
</template>

<script>
import { mdiClose, mdiCloseOutline, mdiImageFilterHdr } from "@mdi/js";
import { ZoomCenterTransition } from "vue2-transitions";
import { mapGetters } from "vuex";
import { GET_IMAGES } from "@/store/actions.type";

export default {
    name: "photography",

    metaInfo() {
        return this.meta(this.$options.name, window.location.href);
    },

    components: {
        HoverCard: () => import("@/components/HoverCard").then(m => m.default),
        ImageCarousel: () => import("@/components/ImageCarousel").then(m => m.default),
        ZoomCenterTransition
    },

    async mounted() {
        await this.$store.dispatch(GET_IMAGES);
        this.filteredImages = this.images;
        this.urls = this.filteredImages.map(image => {return image.url});
        this.folders = this.images.map((el) => { return el.folder_name });
    },

    computed: {
        ...mapGetters(["images", "meta"])
    },

    data() {
        return {
            mdiClose: mdiClose,
            mdiCloseOutline: mdiCloseOutline,
            mdiImageFilterHdr: mdiImageFilterHdr,
            imageIndex: -1,
            overlay: false,
            opacity: 1,
            folders: [],
            search: undefined,
            filter: "",
            filteredImages: [],
            urls: []
        };
    },

    methods: {
        openCarousel(index) {
            this.imageIndex = index;
            this.overlay = true;
        },
        closeCarousel() {
            this.overlay = false;
            this.imageIndex = -1;
        }
    },

    watch: {
        filter(val) {
            if (val.length === 0) {
                this.filteredImages = this.images;
            } else {
                this.filteredImages = this.images.filter(item => val.includes(item.folder_name));
            }
            this.urls = this.filteredImages.map(image => {return image.url});
        }
    }
};
</script>

<style lang="scss">
.slider {
    height: 100%;
    width: 100%;
}

.close {
    top: 10px;
    right: 10px;
    z-index: 100;
}
</style>
